<template>
  <div class="vld-parent cyb-login base-page">
    <login-form :hasForm="false" :top-bar-action="null">
      <template #text-content>
        <div class="p-d-flex p-flex-column p-ai-start p-jc-center page-width p-d-flex-1">
          <div class="content">
            <div class="base-template">
              <p>{{$t('otpl.signingIn')}}</p>
              <progress-spinner style="width:50px;height:50px" strokeWidth="2"/>
            </div>
          </div>
        </div>
      </template>
    </login-form>
  </div>
</template>

<script>
import { authService } from '../services/auth.service';
import { config as routerConfig } from '../router';
import LoginForm from '../components/LoginForm.vue';

export default {
  data() {
    return {
      inProcess: false,
      loginState: {
        count: 0,
        seconds: 0,
        locked: false,
      }
    };
  },
  components: {
    LoginForm
  },
  mounted() {
    this.completeLogin();
  },
  methods: {
    completeLogin() {
      this.inProcess = true;
      try {
        let data = new TextDecoder("utf-8").decode(Buffer.from(this.$route.params.usertoken, 'base64'));
        history.replaceState({}, '', '/');
        if (data) {
          data = data.split("/");
          authService
            .login(null, data[0], data[1])
            .then(() => {
              this.inProcess = false;
              this.$router.push(routerConfig.defaultRoute);
            })
            .catch((error) => {
              this.inProcess = false;
              let em = Buffer.from(JSON.stringify(error.response.data.error)).toString('base64');
              this.$router.push("/login?errmsg="+em);
            });
        } else {
          this.inProcess = false;
          this.$router.push("/login?errmsg=badrequest");
        }
      } catch(e) {
        this.inProcess = false;
        this.$router.push("/login?errmsg=badrequest");
      }
    },
  },
};
</script>
<style>
/* @keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: var(--white);
    }
    50% {
        stroke: rgba(255,255,255,0);
    }
} */
</style>